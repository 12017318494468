module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ajja-website","short_name":"ajja-com","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/img-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b70d42bc92165915676fa983f98b03d9"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"custom":[{"name":["Abed"],"file":"/fonts/Abed.ttf"}],"web":[{"name":"Albert Sans","file":"https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;400;500;600;700&display=swap"},{"name":"Bebas Neue","file":"https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
